import React, { useEffect, useState } from 'react'

import PaperLayout from '../components/paperLayout'
import PaperTitle from '../components/paperTitle'
import GenericTable from '../components/genericTable'
import { useSelector, useDispatch } from 'react-redux'

import { fetchEmployeeAbsenceData } from '../actions/employeeabsence.actions'
import MainLayout from '../layouts/mainLayout'
import { Button } from '@material-ui/core'
import { downloadPDF } from '../services/absenceService'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { STANDARD_DATE_FORMAT } from '../constants/dateformat.constants'

const useStyles = makeStyles(() => ({
    exportButton: { float: 'right' },
}))

const EmployeeAbsence = () => {
    const classes = useStyles()
    //TBC HOW TO GET THIS from query string
    const userInformation = useSelector(
        (state) => state.employeeInformation.data
    )

    const dispatch = useDispatch()
    const employeeAbsenceData = useSelector(
        (state) => state.employeeAbsence.data
    )

    const [tableData, setTableData] = useState(null)

    const [isDownloading, setIsdownloading] = useState(false)

    const handleExportClick = async () => {
        setIsdownloading(true)
        var downloadDate = moment(new Date()).format(STANDARD_DATE_FORMAT)
        var fileDownloadName = `${userInformation.firstName} ${userInformation.surname} Absence report as at ${downloadDate}.pdf`
        await downloadPDF(userInformation.userId, fileDownloadName)
        setIsdownloading(false)
    }

    useEffect(() => {
        if (userInformation) {
            dispatch(fetchEmployeeAbsenceData(userInformation.userId))
        }
    }, [dispatch, userInformation])

    const schema = [
        { id: 'id', name: 'Absence ID' },
        { id: 'caseTrackingRecordId', name: 'Case ID' },
        { id: 'startDateTime', name: 'From', type: 'Date' },
        { id: 'endDateTime', name: 'To', type: 'Date' },
        { id: 'type', name: 'Type' },
        { id: 'reason', name: 'Reason' },
        { id: 'isPartWorkingDay', name: 'Part Day Absence' },
    ]

    useEffect(() => {
        if (employeeAbsenceData.length > 0) {
            setTableData(
                employeeAbsenceData.map((row) => {
                    return {
                        ...row,
                        isPartWorkingDay:
                            row.isPartWorkingDay === true ? 'Yes' : 'No',
                    }
                })
            )
        }
    }, [employeeAbsenceData])

    return (
        <MainLayout>
            <PaperLayout>
                <PaperTitle title={'Employee Absence'} />
                <Button
                    className={classes.exportButton}
                    disabled={isDownloading}
                    variant="contained"
                    color="secondary"
                    onClick={handleExportClick}
                >
                    {isDownloading
                        ? 'Exporting Absences...'
                        : 'Export Absences'}
                </Button>
                <GenericTable
                    schema={schema}
                    data={tableData}
                    defaultOrderBy="startDate"
                    defaultRowsPerPage="25"
                    defaultOrder="desc"
                />
            </PaperLayout>
        </MainLayout>
    )
}

export default EmployeeAbsence
