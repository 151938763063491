import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
}))

const DrawerHeader = ({ children }) => {
    const classes = useStyles()

    return <div className={classes.drawerHeader}>{children}</div>
}

export default DrawerHeader
