import httpClient from '../utils/axios'
import { getEmployeeAbsencesBlob } from './download.service'

const getAbsenceDataFromApiAsync = async (userId) => {
    return httpClient
        .get(`${window.config.USER_API_URL}/api/user/${userId}/absence`)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.log(error)
            return null
        })
}

export const downloadPDF = async (userId, fileDownloadName) => {
    return getEmployeeAbsencesBlob(userId, fileDownloadName)
}

export default {
    getAbsenceDataFromApiAsync,
}
