import React, { useState } from 'react'

import {
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))

export const EventHistoryExportModal = ({
    open,
    handleExport,
    handleClose,
    topics,
    disabled,
}) => {
    const classes = useStyles()
    const [selectedTopic, setSelectedTopic] = useState(null)
    const handleChange = (event) => {
        setSelectedTopic(event.target.value)
    }
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{'Export'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Select the topic you would like to export
                </DialogContentText>
                <FormControl className={classes.formControl}>
                    <InputLabel id="topic-select-label">Topic</InputLabel>
                    <Select
                        disabled={disabled}
                        labelId="topic-select-label"
                        id="topic-select"
                        value={selectedTopic}
                        onChange={handleChange}
                    >
                        {topics &&
                            topics.map((topic) => (
                                <MenuItem value={topic} key={topic}>
                                    {topic}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={() => handleExport(selectedTopic)}
                    color="primary"
                    disabled={disabled}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}
