import React, { useEffect } from 'react'
import { signOutRedirectCallback } from '../services/auth.service'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        margin: '32px',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

function SignOutCallback() {
    const classes = useStyles()
    const history = useHistory()

    useEffect(() => {
        signOutRedirectCallback().then(() => history.push('/signed-out'))
    }, [history])

    return (
        <div className={classes.container}>
            <CircularProgress />
            <p>Logging out</p>
        </div>
    )
}

export default SignOutCallback
