import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as OopsImage } from '../images/donut_love.svg'

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '32px',
    },
    imageContainer: {
        maxWidth: '600px',
        marginBottom: '32px',
    },
    image: {
        height: '100%',
        width: '100%',
    },
}))

const Oops = () => {
    const classes = useStyles()
    return (
        <div className={classes.pageContainer}>
            <div className={classes.imageContainer}>
                <OopsImage className={classes.image} />
            </div>
            <p>Oops. Something went wrong there.</p>
        </div>
    )
}

export default Oops
