import {
    EMPLOYEE_ABSENCE_REQUEST,
    EMPLOYEE_ABSENCE_SUCCESS,
    EMPLOYEE_ABSENCE_FAILURE,
} from './types'

import absenceService from '../services/absenceService'

function employeeAbsenceDataRequest() {
    return {
        type: EMPLOYEE_ABSENCE_REQUEST,
    }
}

function employeeAbsenceDataSuccess(response) {
    return {
        type: EMPLOYEE_ABSENCE_SUCCESS,
        payload: response,
    }
}

function employeeAbsenceDataFailure(error) {
    return {
        type: EMPLOYEE_ABSENCE_FAILURE,
        payload: error,
    }
}

export const fetchEmployeeAbsenceData = (userId) => {
    return async (dispatch) => {
        dispatch(employeeAbsenceDataRequest())
        try {
            const response = await absenceService.getAbsenceDataFromApiAsync(
                userId
            )
            return dispatch(employeeAbsenceDataSuccess(response))
        } catch (error) {
            return dispatch(employeeAbsenceDataFailure(error))
        }
    }
}
