import React, { useEffect, useRef } from 'react'
import { storeUser } from '../actions/auth.actions'

const AuthenticationProvider = ({ userService: manager, store, children }) => {
    let userManager = useRef()
    useEffect(() => {
        userManager.current = manager

        const onUserLoaded = (user) => {
            console.log('user loaded event')
            store.dispatch(storeUser(user))
        }

        const onUserUnloaded = () => {
            console.log('unloaded user event')
        }

        const onAccessTokenExpiring = () => {
            console.log('token expiring event')
        }

        const onAccessTokenExpired = () => {
            console.log('token expired event')
        }

        const onSilentRenewError = () => {
            console.log('silent renew error event')
        }

        const onUserSignedOut = () => {
            console.log(`user signed out event`)
        }

        // Raised when a user session has been established (or re-established).
        userManager.current.events.addUserLoaded(onUserLoaded)
        // Raised when a user session has been terminated.
        userManager.current.events.addUserUnloaded(onUserUnloaded)
        // Raised prior to the access token expiring.
        userManager.current.events.addAccessTokenExpiring(onAccessTokenExpiring)
        // Raised after the access token has expired.
        userManager.current.events.addAccessTokenExpired(onAccessTokenExpired)
        // Raised when the automatic silent renew has failed.
        userManager.current.events.addSilentRenewError(onSilentRenewError)
        // Raised when the user's sign-in status at the OP has changed.
        userManager.current.events.addUserSignedOut(onUserSignedOut)

        return () => {
            userManager.current.events.removeUserLoaded(onUserLoaded)
            userManager.current.events.removeUserUnloaded(onUserUnloaded)
            userManager.current.events.removeAccessTokenExpiring(
                onAccessTokenExpiring
            )
            userManager.current.events.removeAccessTokenExpired(
                onAccessTokenExpired
            )
            userManager.current.events.removeSilentRenewError(
                onSilentRenewError
            )
            userManager.current.events.removeUserSignedOut(onUserSignedOut)
        }
    }, [manager, store])

    return React.Children.only(children)
}

export default AuthenticationProvider
