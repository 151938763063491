import httpClient from '../utils/axios'
import {
    handleServiceResponse,
    handleServiceError,
} from '../utils/serviceResponseHelper'

function getServiceBranding() {
    return httpClient
        .get(`${window.config.CONFIG_API_URL}/api/branding/application`)
        .then(
            (response) => handleServiceResponse(response),
            (error) => handleServiceError(error)
        )
}

export default {
    getServiceBranding,
}
