import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import { CardContent } from '@material-ui/core'

import { useSelector } from 'react-redux'

const useStyles = makeStyles(() => ({
    card: {
        boxShadow: 'none',
        overflow: 'visible',
    },
    avatar: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#42424e',
        color: '#42424e',
    },
    avatarHighlighted: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#e74c3c',
        color: '#e74c3c',
    },
    employeeDataItem: {
        marginBottom: 5,
        overflow: 'hidden',
    },
}))

const EmployeeDataItem = ({ title, data }) => {
    const classes = useStyles()
    return (
        <div className={classes.employeeDataItem}>
            <FormHelperText>{title}</FormHelperText>
            {data}
        </div>
    )
}

const SubHeader = ({ jobTitle, employeeNumber }) => {
    return (
        <>
            <div>{jobTitle}</div>
            <div>{employeeNumber}</div>
        </>
    )
}

const EmployeeOverview = () => {
    const classes = useStyles()
    const employeeData = useSelector((state) => state.employeeInformation.data)
    const { isLoading } = useSelector((state) => state.employeeInformation)

    function getInitials(firstName, lastName) {
        return `${firstName
            .trim()
            .substring(0, 1)
            .toUpperCase()}${lastName.trim().substring(0, 1).toUpperCase()}`
    }

    function fieldsToShow() {
        let phoneNumber = null
        let emailAddress = null
        if (employeeData) {
            if (employeeData.dataMap) {
                employeeData.dataMap.forEach((section) => {
                    section.fields.forEach((field) => {
                        if (field.name === 'phone') phoneNumber = field
                        if (field.name === 'email') emailAddress = field
                    })
                })
            }
        }
        return (
            <>
                {phoneNumber && (
                    <EmployeeDataItem
                        title={phoneNumber.label}
                        data={employeeData.phone}
                    />
                )}
                {emailAddress && (
                    <EmployeeDataItem
                        title={emailAddress.label}
                        data={employeeData.email}
                    />
                )}
                {employeeData.manager && (
                    <EmployeeDataItem
                        title={'Manager'}
                        data={`${employeeData.manager.firstName} ${employeeData.manager.lastName}`}
                    />
                )}
            </>
        )
    }

    return isLoading ? null : (
        <>
            {employeeData && (
                <Card className={classes.card}>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label={employeeData.title}
                                className={
                                    employeeData.userComments
                                        ? classes.avatarHighlighted
                                        : classes.avatar
                                }
                            >
                                {getInitials(
                                    employeeData.firstName,
                                    employeeData.surname
                                )}
                            </Avatar>
                        }
                        title={`${employeeData.firstName} ${employeeData.surname}`}
                        subheader={
                            <SubHeader
                                jobTitle={employeeData.position}
                                employeeNumber={employeeData.staffId}
                            />
                        }
                    />
                    <CardContent style={{ paddingTop: 8 }}>
                        {employeeData && fieldsToShow()}
                    </CardContent>
                </Card>
            )}
        </>
    )
}

export default EmployeeOverview
