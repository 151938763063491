import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { STANDARD_DATE_FORMAT } from '../constants/dateformat.constants'
import PaperLayout from '../components/paperLayout'
import PaperTitle from '../components/paperTitle'
import PaperSubTitle from '../components/paperSubTitle'
import { FormControl, TextField, makeStyles } from '@material-ui/core'
import MainLayout from '../layouts/mainLayout'
import { relationshipTypes } from '../constants/string.constants'

const useStyles = makeStyles(() => ({
    formControl: {
        margin: '10px 20px',
    },
    heading: {
        margin: '10px 20px',
    },
    section: {
        marginBottom: 30,
        marginTop: 30,
    },
    paper: {
        marginBottom: 30,
    },
    sectionPaper: {
        marginBottom: 30,
    },
    subTitle: {
        marginTop: 10,
    },
}))

const EmployeeInformation = () => {
    const classes = useStyles()

    const userInformation = useSelector(
        (state) => state.employeeInformation.data
    )
    const { serviceLabelData } = useSelector((state) => state.serviceLabel)

    function getValueToDisplay(value, type) {
        try {
            switch (type) {
                case 'string':
                    return getString(value)
                case 'decimal':
                    return getDecimal(value)
                case 'date':
                    return getDate(value)
                case 'bool':
                    return getBool(value)
                default:
                    return ' '
            }
        } catch (err) {
            console.error(err)
            return ' ' //BLANK TO FORCE MATERIAL STYLE
        }
    }

    function getString(value) {
        if (!value) return ' ' //BLANK TO FORCE MATERIAL STYLE
        if (value.trim() === '') return ' '
        return value
    }

    function getDecimal(value) {
        if (!value) return ' ' //BLANK TO FORCE MATERIAL STYLE
        return value
    }

    function getDate(value) {
        if (!value) return ' ' //BLANK TO FORCE MATERIAL STYLE
        return moment(value).format(STANDARD_DATE_FORMAT)
    }

    function getBool(value) {
        return value ? 'Yes' : 'No'
    }

    function buildField(label, value, type) {
        return (
            <FormControl className={classes.formControl}>
                <TextField
                    label={label}
                    defaultValue={getValueToDisplay(value, type)}
                    InputProps={{ readOnly: true }}
                />
            </FormControl>
        )
    }

    function buildSection(section) {
        return (
            <div className={classes.sectionPaper}>
                <PaperTitle title={section.name} />
                {section.fields
                    .slice()
                    .sort((a, b) => a.orderBy > b.orderBy)
                    .map((field) =>
                        buildField(
                            field.label,
                            userInformation[field.name],
                            field.dataType
                        )
                    )}

                {section.sectionType === 'businessStructure' &&
                    buildBusinessUserContent()}
                {section.sectionType === 'contactDetails' &&
                    buildPersonEmailAddresses()}
                {section.sectionType === 'contactDetails' &&
                    buildPersonPhoneNumbers()}
                {section.sectionType === 'contactDetails' &&
                    buildPersonAddresses()}
                {section.sectionType === 'businessRelationship' &&
                    buildBusinessUserRelationshipContent()}
            </div>
        )
    }

    function buildContent() {
        return serviceLabelData
            .slice()
            .sort((a, b) => a.orderBy > b.orderBy)
            .map((section) => buildSection(section))
    }

    function getBusinessUserRelationshipLabel(typeId) {
        return relationshipTypes[typeId]
    }

    function buildBusinessUserRelationshipContent() {
        if (
            !userInformation ||
            !userInformation.businessUserRelationships.length
        )
            return

        return (
            <div className={classes.sectionPaper}>
                {userInformation.businessUserRelationships
                    .slice()
                    .reverse()
                    .map((struct) =>
                        buildField(
                            getBusinessUserRelationshipLabel(
                                struct.businessUserRelationshipType
                            ),
                            struct.personNameInfo.fullName,
                            'string'
                        )
                    )}
            </div>
        )
    }

    function buildBusinessUserContent() {
        if (
            !userInformation ||
            !userInformation.businessStructureColumns.length
        )
            return

        return (
            <div className={classes.sectionPaper}>
                {userInformation.businessStructureColumns
                    .slice()
                    .sort((a, b) => a.order > b.order)
                    .map((struct) =>
                        buildField(struct.description, struct.value, 'string')
                    )}
            </div>
        )
    }

    function buildPersonEmailAddresses() {
        if (!userInformation || !userInformation.personEmailAddresses.length)
            return

        return (
            <div className={classes.sectionPaper}>
                <PaperSubTitle title="Email Addresses" />
                {userInformation.personEmailAddresses
                    .slice()
                    .sort((a, b) => a.order > b.order)
                    .map((struct) =>
                        buildField(
                            struct.emailAddressType,
                            struct.emailAddress,
                            'string'
                        )
                    )}
            </div>
        )
    }

    function buildPersonAddresses() {
        if (!userInformation || !userInformation.personAddresses.length) return

        return (
            <div className={classes.sectionPaper}>
                <PaperSubTitle title="Addresses" />
                {userInformation.personAddresses
                    .slice()
                    .sort((a, b) => a.order > b.order)
                    .flatMap((personAddress) => [
                        buildField(
                            'AddressLine1',
                            personAddress.addressLine1,
                            'string'
                        ),
                        buildField(
                            'AddressLine2',
                            personAddress.addressLine2,
                            'string'
                        ),
                        buildField(
                            'AddressLine3',
                            personAddress.addressLine3,
                            'string'
                        ),
                        buildField(
                            'AddressLine4',
                            personAddress.addressLine4,
                            'string'
                        ),
                        buildField(
                            'Postcode',
                            personAddress.postcode,
                            'string'
                        ),
                    ])}
            </div>
        )
    }

    function buildPersonPhoneNumbers() {
        if (!userInformation || !userInformation.personPhones.length) return

        return (
            <div className={classes.sectionPaper}>
                <PaperSubTitle title="Phone Numbers" />
                {userInformation.personPhones
                    .slice()
                    .sort((a, b) => a.order > b.order)
                    .map((struct) =>
                        buildField(
                            struct.phoneNumberType,
                            struct.phoneNumber,
                            'string'
                        )
                    )}
            </div>
        )
    }

    return (
        <MainLayout>
            <PaperLayout>
                {userInformation && serviceLabelData && buildContent()}
            </PaperLayout>
        </MainLayout>
    )
}

export default EmployeeInformation
