import httpClient from '../utils/axios'
import {
    handleServiceError,
    handleServiceResponse,
} from '../utils/serviceResponseHelper'
import { enqueueSnackbar } from '../actions/notification.actions'
import store from '../store'

const getEmployeeDataFromApiAsync = async (userId) => {
    var url = `${window.config.USER_API_URL}/api/user/${userId}/profile`
    return httpClient
        .get(url)
        .then((response) => {
            return handleServiceResponse(response)
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.errorMessage
            store.dispatch(
                enqueueSnackbar({
                    message: errorMessage || 'Could not fetch employee data',
                    options: {
                        variant: 'warning',
                    },
                })
            )
            return handleServiceError(error)
        })
}

const updateEmployeeUserCommentAsync = async (userId, userComments) => {
    var url = `${window.config.USER_API_URL}/api/user/${userId}/updatecomments`
    return httpClient
        .put(url, JSON.stringify({ Comments: userComments }), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            store.dispatch(
                enqueueSnackbar({
                    message: `Saved`,
                    options: {
                        variant: 'success',
                    },
                })
            )
            return handleServiceResponse(response.data)
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.errorMessage
            store.dispatch(
                enqueueSnackbar({
                    message: errorMessage || 'Could not update user comments',
                    options: {
                        variant: 'warning',
                    },
                })
            )
            return handleServiceError(error)
        })
}

const getEmployeesNamesAsync = async (employeeIds) => {
    const url = `${window.config.USER_API_URL}/api/user/names`
    const { data } = await httpClient.post(url, JSON.stringify(employeeIds), {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    return data.data
}

export default {
    getEmployeeDataFromApiAsync,
    updateEmployeeUserCommentAsync,
    getEmployeesNamesAsync,
}
