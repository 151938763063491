import React, { useState, useEffect } from 'react'
import PaperLayout from '../components/paperLayout'
import PaperTitle from '../components/paperTitle'
import MainLayout from '../layouts/mainLayout'
import GenericTable from '../components/genericTable'
import PaperSubTitle from '../components/paperSubTitle'
import { makeStyles } from '@material-ui/core'
import caseService from '../services/case.service'
import { useSelector } from 'react-redux'

const schema = [
    { id: 'id', name: 'Case Number' },
    { id: 'dateCreated', name: 'Opened', type: 'Date' },
    { id: 'dateClosed', name: 'Closed', type: 'Date' },
    { id: 'topic', name: 'Topic' },
    { id: 'risk', name: 'Risk' },
    { id: 'adviser', name: 'Adviser' },
    { id: 'caseOwner', name: 'Case Owner' },
]

const mapCaseDataToTableObject = (caseData) => {
    return {
        id: caseData.id,
        adviser: caseData.caseAdviser?.adviserName,
        adviserGuid: caseData.caseAdviser?.adviserId,
        employee: caseData.caseEmployee?.employeePersonInfo?.fullName,
        employeeGuid: caseData.caseEmployee?.employeePersonInfo?.userId,
        topic: caseData.topic?.title,
        risk: caseData.risk?.title,
        caseOwner: caseData.caseOwner?.participantPersonInfo?.fullName,
        caseOwnerGuid: caseData.caseOwner?.participantPersonInfo?.userId,
        dateCreated: caseData.dateCreated,
        dateClosed: caseData.dateClosed,
    }
}

const useStyles = makeStyles((theme) => ({
    section: {
        marginBottom: 30,
        marginTop: 30,
    },
}))

const CaseHistory = () => {
    const classes = useStyles()
    const [hrCases, setHrCases] = useState([])
    const [employeeCases, setEmployeeCases] = useState([])
    const [participantCases, setParticipantCases] = useState([])
    const userInformation = useSelector(
        (state) => state.employeeInformation.data
    )

    useEffect(() => {
        async function getEmployeeCases() {
            if (userInformation?.userId) {
                const fetchEmployeeCases = caseService.getEmployeeCaseHistoryAsync(
                    userInformation.userId
                )
                const fetchParticipantCases = caseService.getParticipantCaseHistoryAsync(
                    userInformation.userId
                )
                const fetchHrCases = caseService.getHrCaseHistoryAsync(
                    userInformation.userId
                )
                try {
                    const [
                        employeeCases,
                        participantCases,
                        hrCases,
                    ] = await Promise.all([
                        fetchEmployeeCases,
                        fetchParticipantCases,
                        fetchHrCases,
                    ])

                    const mappedEmployeeCases = employeeCases.map((caseItem) =>
                        mapCaseDataToTableObject(caseItem)
                    )
                    const mappedParticipantCases = participantCases.map(
                        (caseItem) => mapCaseDataToTableObject(caseItem)
                    )
                    const mappedHrCases = hrCases.map((caseItem) =>
                        mapCaseDataToTableObject(caseItem)
                    )

                    setEmployeeCases(mappedEmployeeCases)
                    setParticipantCases(mappedParticipantCases)
                    setHrCases(mappedHrCases)
                } catch (error) {
                    console.log(error)
                }
            }
        }
        getEmployeeCases()
    }, [userInformation])

    return (
        <MainLayout>
            <PaperLayout>
                <PaperTitle title={'Employee Case History'} />
                <section className={classes.section}>
                    <PaperSubTitle title={'As HR User'} />
                    <GenericTable data={hrCases} schema={schema} />
                </section>
                <section className={classes.section}>
                    <PaperSubTitle title={'As Participant'} />
                    <GenericTable data={participantCases} schema={schema} />
                </section>
                <section className={classes.section}>
                    <PaperSubTitle title={'As Employee'} />
                    <GenericTable data={employeeCases} schema={schema} />
                </section>
            </PaperLayout>
        </MainLayout>
    )
}

export default CaseHistory
