import httpClient from '../utils/axios'
import { handleServiceError } from '../utils/serviceResponseHelper'
import userService from './user.service'
import { UNKNOWN_STRING_DEFAULT } from '../constants/string.constants'
import { getEventExportBlob } from './download.service'

const getEventHistoryDataFromApiAsync = async (employeeId) => {
    var url = `${window.config.CASE_API_URL}/api/employees/eventHistory/${employeeId}`
    return httpClient
        .get(url)
        .then((response) => {
            const events = response.data.data.employeeEventHistoryEvents

            // get case owners names from separate api
            const caseOwnerIds = response.data.data.caseOwnerIds

            return userService
                .getEmployeesNamesAsync(caseOwnerIds)
                .then((userData) => {
                    events.forEach((event) => {
                        const caseOwner = userData.find(
                            (userRecord) =>
                                userRecord.userId === event.caseOwnerId
                        )
                        event.caseOwnerName = caseOwner
                            ? caseOwner.fullName
                            : UNKNOWN_STRING_DEFAULT
                    })

                    return events
                })
                .catch((error) => {
                    handleServiceError(error)
                })
        })
        .catch((error) => {
            handleServiceError(error)
        })
}

const getEventHistoryExtraInfoDataFromApiAsync = async (
    workflowTrackingEventId
) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            // set up test data
            var extraInfo = {
                workflowTrackingEventId: workflowTrackingEventId,
                details: [
                    {
                        workflowTrackingEventId: workflowTrackingEventId,
                        testData: 'TestData 1' + workflowTrackingEventId,
                    },
                    {
                        workflowTrackingEventId: workflowTrackingEventId,
                        testData: 'TestData 1-2',
                    },
                    {
                        workflowTrackingEventId: workflowTrackingEventId,
                        testData: 'TestData 1-3',
                    },
                ],
            }

            resolve(extraInfo)
        }, 2000)
    })
}

export const downloadPDF = async (userId, selectedTopic, fileDownloadName) => {
    return getEventExportBlob(userId, selectedTopic, fileDownloadName)
}

export default {
    getEventHistoryDataFromApiAsync,
    getEventHistoryExtraInfoDataFromApiAsync,
}
