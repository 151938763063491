import {
    EVENT_HISTORY_REQUEST,
    EVENT_HISTORY_SUCCESS,
    EVENT_HISTORY_EXTRA_INFO_SUCCESS,
    EVENT_HISTORY_FAILURE,
} from './types'

import eventHistoryService from '../services/eventHistory.service'

function eventHistoryDataRequest() {
    return {
        type: EVENT_HISTORY_REQUEST,
    }
}

function eventHistoryDataSuccess(response) {
    return {
        type: EVENT_HISTORY_SUCCESS,
        payload: response,
    }
}

function eventHistoryExtraInfoDataSuccess(response) {
    return {
        type: EVENT_HISTORY_EXTRA_INFO_SUCCESS,
        payload: response,
    }
}

function eventHistoryDataFailure(error) {
    return {
        type: EVENT_HISTORY_FAILURE,
        payload: error,
    }
}

export const fetchEventHistoryData = (userId) => {
    return async (dispatch) => {
        dispatch(eventHistoryDataRequest())
        try {
            const response = await eventHistoryService.getEventHistoryDataFromApiAsync(
                userId
            )
            return dispatch(eventHistoryDataSuccess(response))
        } catch (error) {
            return dispatch(eventHistoryDataFailure(error))
        }
    }
}

export const fetchEventHistoryExtraInfoData = (userId) => {
    return async (dispatch) => {
        //dispatch(eventHistoryExtraInfoDataRequest());
        try {
            const response = await eventHistoryService.getEventHistoryExtraInfoDataFromApiAsync(
                userId
            )
            return dispatch(eventHistoryExtraInfoDataSuccess(response))
        } catch (error) {
            //return dispatch(eventHistoryDataFailure(error));
        }
    }
}
