import { createReducer } from '@reduxjs/toolkit'

import {
    EMPLOYEE_INFO_REQUEST,
    EMPLOYEE_INFO_SUCCESS,
    EMPLOYEE_INFO_FAILURE,
    EMPLOYEE_PERMISSION_FAILURE,
    UPDATE_USER_COMMENT_REQUEST,
    UPDATE_USER_COMMENT_SUCCESS,
    UPDATE_USER_COMMENT_FAILURE,
} from '../actions/types'

const initialState = {
    data: null,
    isLoading: true,
    error: null,
}

export default createReducer(initialState, {
    [EMPLOYEE_INFO_REQUEST]: (state) => {
        state.isLoading = true
    },
    [EMPLOYEE_INFO_SUCCESS]: (state, action) => {
        state.data = action.payload.data
        state.isLoading = false
        state.error = null
    },
    [EMPLOYEE_INFO_FAILURE]: (state, action) => {
        state.data = null
        state.isLoading = false
        state.error = action.payload
    },
    [EMPLOYEE_PERMISSION_FAILURE]: (state, action) => {
        state.data = null
        state.isLoading = false
        state.error = action.payload
    },
    [UPDATE_USER_COMMENT_REQUEST]: (state) => {
        state.updatingUserComment = true
    },
    [UPDATE_USER_COMMENT_SUCCESS]: (state, action) => {
        state.updatingUserComment = false
        state.data.userComments = action.payload.data.data
    },
    [UPDATE_USER_COMMENT_FAILURE]: (state) => {
        state.updatingUserComment = false
    },
})
