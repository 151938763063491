import { createReducer } from '@reduxjs/toolkit'

import {
    EMPLOYEE_ABSENCE_REQUEST,
    EMPLOYEE_ABSENCE_SUCCESS,
    EMPLOYEE_ABSENCE_FAILURE,
} from '../actions/types'

const initialState = {
    data: [],
    isLoading: false,
    error: null,
}

export default createReducer(initialState, {
    [EMPLOYEE_ABSENCE_REQUEST]: (state) => {
        state.isLoading = true
        state.error = null
    },
    [EMPLOYEE_ABSENCE_SUCCESS]: (state, action) => {
        state.data = action.payload.data
        state.isLoading = false
        state.error = null
    },
    [EMPLOYEE_ABSENCE_FAILURE]: (state, action) => {
        state.data = []
        state.isLoading = false
        state.error = action.payload
    },
})
