import React, { useState, useEffect } from 'react'
import { Button, TextField } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import MainLayout from '../layouts/mainLayout'
import PaperLayout from '../components/paperLayout'
import PaperTitle from '../components/paperTitle'

import { updateEmployeeUserComment } from '../actions/employeeinfo.actions'

const UserComments = () => {
    const dispatch = useDispatch()
    const userInformation = useSelector(
        (state) => state.employeeInformation.data
    )
    const updatingUserComment = useSelector(
        (state) => state.employeeInformation.updatingUserComment
    )

    const [userComment, setUserComment] = useState('')

    useEffect(() => setUserComment(userInformation?.comments ?? ''), [
        userInformation,
    ])

    const handleUpdateClick = () => {
        console.log(userInformation)
        dispatch(updateEmployeeUserComment(userInformation.userId, userComment))
    }

    return (
        <MainLayout>
            <PaperLayout>
                <PaperTitle title={'User Comments'} />
                <TextField
                    disabled={updatingUserComment}
                    id="outlined-multiline-static"
                    label="User Comments"
                    multiline
                    rows={10}
                    variant="outlined"
                    style={{ width: '100%', height: '100%', marginTop: 20 }}
                    value={userComment}
                    onChange={(e) => setUserComment(e.target.value)}
                />
                <div style={{ marginTop: 10, display: 'flex' }}>
                    <Button
                        disabled={updatingUserComment}
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: 'auto' }}
                        onClick={handleUpdateClick}
                    >
                        {updatingUserComment ? 'Saving...' : 'Update'}
                    </Button>
                </div>
            </PaperLayout>
        </MainLayout>
    )
}

export default UserComments
