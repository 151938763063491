export const UNKNOWN_STRING_DEFAULT = 'N/A'

export const USER_ID_KEY = 'up:userId'

export const relationshipTypes = {
    1: 'Respondent',
    2: 'Interested Party',
    3: 'HR Business Partner',
    4: 'Trade Union Representative',
    5: 'First Line Manager',
    6: 'Second Line Manager',
    7: 'Third Line Manager',
    8: 'Role Cover Manager',
    9: 'Investigation Manager',
    10: 'Hearing Manager',
    12: 'Appeal Manager',
    13: 'Other',
    14: 'Fourth Line Manager',
    15: 'Fifth Line Manager',
}

export const NOT_AUTHORISED = 'NOT_AUTHORISED'
