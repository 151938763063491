import {
    SERVICE_LABEL_DATA_REQUEST,
    SERVICE_LABEL_DATA_SUCCESS,
    SERVICE_LABEL_DATA_FAILURE,
} from '../actions/types'

import { createReducer } from '@reduxjs/toolkit'

const defaultServiceLabelData = [
    {
        name: 'Personal Details',
        sectionType: 'personalDetails',
        orderBy: 0,
        fields: [
            {
                name: 'firstName',
                label: 'Forename',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'preferredFirstName',
                label: 'Known As',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'surname',
                label: 'Surname',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'dateOfBirth',
                label: 'Date of Birth',
                dataType: 'date',
                orderBy: 0,
            },
        ],
    },
    {
        name: 'Contact Details',
        sectionType: 'contactDetails',
        orderBy: 0,
        fields: [],
    },
    {
        name: 'Employment Details',
        sectionType: 'employmentDetails',
        orderBy: 0,
        fields: [
            {
                name: 'jobTitle',
                label: 'Job Title',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'grade',
                label: 'Grade',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'employmentStartDateTime',
                label: 'Start Date',
                dataType: 'date',
                orderBy: 0,
            },
            {
                name: 'continuousServiceDateTime',
                label: 'Continuous Service Date',
                dataType: 'date',
                orderBy: 0,
            },
            {
                name: 'workingHours',
                label: 'Working Hours',
                dataType: 'decimal',
                orderBy: 0,
            },
            {
                name: 'noticePeriod',
                label: 'Notice Period',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'probationEndDateTime',
                label: 'Probation Date',
                dataType: 'date',
                orderBy: 0,
            },
            {
                name: 'employmentEndDateTime',
                label: 'Leave Date',
                dataType: 'date',
                orderBy: 0,
            },
            {
                name: 'employmentEndReason',
                label: 'Leave Reason',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'isManager',
                label: 'Is a Manager?',
                dataType: 'bool',
                orderBy: 0,
            },
        ],
    },
    {
        name: 'Business Structure',
        sectionType: 'businessStructure',
        orderBy: 0,
        fields: [],
    },
    {
        name: 'Business Relationship',
        sectionType: 'businessRelationship',
        orderBy: 0,
        fields: [],
    },
]

const initialState = {
    serviceLabelData: defaultServiceLabelData,
    isLoading: false,
    error: null,
}

export default createReducer(initialState, {
    [SERVICE_LABEL_DATA_REQUEST]: (state) => {
        state.isLoading = true
    },
    [SERVICE_LABEL_DATA_SUCCESS]: (state, action) => {
        state.serviceLabelData = defaultServiceLabelData //TODO: Add service label to new world
        state.isLoading = false
        state.error = null
    },
    [SERVICE_LABEL_DATA_FAILURE]: (state, action) => {
        state.isLoading = false
        state.error = action.payload
    },
})
