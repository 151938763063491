import {
    EMPLOYEE_INFO_REQUEST,
    EMPLOYEE_INFO_SUCCESS,
    EMPLOYEE_INFO_FAILURE,
    EMPLOYEE_PERMISSION_FAILURE,
    UPDATE_USER_COMMENT_REQUEST,
    UPDATE_USER_COMMENT_SUCCESS,
    UPDATE_USER_COMMENT_FAILURE,
} from './types'
import history from '../history'
import userService from '../services/user.service'
import { NOT_AUTHORISED } from '../constants/string.constants'

function employeeDataRequest() {
    return {
        type: EMPLOYEE_INFO_REQUEST,
    }
}

function employeeDataSuccess(response) {
    return {
        type: EMPLOYEE_INFO_SUCCESS,
        payload: response,
    }
}

function employeeDataFailure(error) {
    return {
        type: EMPLOYEE_INFO_FAILURE,
        payload: error,
    }
}

function employeePermissionFailure(error) {
    return {
        type: EMPLOYEE_PERMISSION_FAILURE,
        payload: error,
    }
}

export const fetchEmployeeData = (userId) => {
    return async (dispatch) => {
        dispatch(employeeDataRequest())
        try {
            const response = await userService.getEmployeeDataFromApiAsync(
                userId
            )
            if (response.errorCode === NOT_AUTHORISED) {
                dispatch(employeePermissionFailure(response.errorCode))
                history.push('/permissions')
            } else {
                dispatch(employeeDataSuccess(response))
            }
        } catch (error) {
            dispatch(employeeDataFailure(error))
            history.push('/oops')
        }
    }
}

function updateUserCommentRequest() {
    return {
        type: UPDATE_USER_COMMENT_REQUEST,
    }
}

function updateUserCommentSuccess(response) {
    return {
        type: UPDATE_USER_COMMENT_SUCCESS,
        payload: response,
    }
}

function updateUserCommentFailure(error) {
    return {
        type: UPDATE_USER_COMMENT_FAILURE,
        payload: error,
    }
}

export const updateEmployeeUserComment = (userId, userComment) => {
    return async (dispatch) => {
        dispatch(updateUserCommentRequest())
        try {
            const response = await userService.updateEmployeeUserCommentAsync(
                userId,
                userComment
            )
            return dispatch(updateUserCommentSuccess(response))
        } catch (error) {
            return dispatch(updateUserCommentFailure(error))
        }
    }
}
