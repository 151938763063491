import React, { useState } from 'react'
import NavigationContext from './navigationContext'
import qs from 'query-string'
import history from '../../history'

import { USER_ID_KEY } from '../../constants/string.constants'

const NavigationProvider = ({ children }) => {
    const [userId, setUserId] = useState(null)

    const processQueryParams = (href) => {
        const parsedUrl = qs.parseUrl(href)
        const { query } = parsedUrl

        if (query.userId) {
            setUserId(query.userId)
            localStorage.setItem(USER_ID_KEY, query.userId)
        } else {
            const userIdFromStorage = localStorage.getItem(USER_ID_KEY)
            if (userIdFromStorage) {
                setUserId(userIdFromStorage)
            } else {
                history.push('/oops')
            }
        }
    }

    const contextValue = {
        processQueryParams,
        userId,
    }

    return (
        <NavigationContext.Provider value={contextValue}>
            {children}
        </NavigationContext.Provider>
    )
}

export default NavigationProvider
