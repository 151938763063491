import httpClient from '../utils/axios'
import {
    handleServiceResponse,
    handleServiceError,
} from '../utils/serviceResponseHelper'

const getEmployeeCaseHistoryAsync = async (userId) => {
    const url = `${window.config.CASE_API_URL}/api/openclosedcasesuser/${userId}`
    try {
        const caseDataResponse = await httpClient.get(url)
        const { data: caseData } = handleServiceResponse(caseDataResponse)
        return caseData
    } catch (error) {
        return handleServiceError(error)
    }
}

const getParticipantCaseHistoryAsync = async (userId) => {
    const url = `${window.config.CASE_API_URL}/api/casesparticipant/${userId}`
    try {
        const caseDataResponse = await httpClient.get(url)
        const { data: caseData } = handleServiceResponse(caseDataResponse)
        return caseData
    } catch (error) {
        return handleServiceError(error)
    }
}

const getHrCaseHistoryAsync = async (userId) => {
    const url = `${window.config.CASE_API_URL}/api/caseshr/${userId}`
    try {
        const caseDataResponse = await httpClient.get(url)
        const { data: caseData } = handleServiceResponse(caseDataResponse)
        return caseData
    } catch (error) {
        return handleServiceError(error)
    }
}

export default {
    getEmployeeCaseHistoryAsync,
    getParticipantCaseHistoryAsync,
    getHrCaseHistoryAsync,
}
