import {
    STORE_USER,
    STORE_USER_ERROR,
    SERVICE_ACCESS_SUCCESS,
    SERVICE_ACCESS_REQUEST,
    SERVICE_ACCESS_FAILURE,
} from './types'
import { checkServiceAccess } from '../services/auth.service'

export function storeUser(user) {
    return {
        type: STORE_USER,
        payload: user,
    }
}

export function storeUserError() {
    return {
        type: STORE_USER_ERROR,
    }
}

export function serviceAccessCheck() {
    return async (dispatch) => {
        dispatch(serviceAccessRequest())
        try {
            const allowed = await checkServiceAccess()
            dispatch(serviceAccessSuccess(allowed))
        } catch (error) {
            dispatch(serviceAccessFailure())
        }
    }

    function serviceAccessRequest() {
        return {
            type: SERVICE_ACCESS_REQUEST,
        }
    }

    function serviceAccessSuccess(response) {
        return {
            type: SERVICE_ACCESS_SUCCESS,
            payload: response,
        }
    }

    function serviceAccessFailure() {
        return {
            type: SERVICE_ACCESS_FAILURE,
        }
    }
}
