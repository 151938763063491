import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'

const CustomBackdrop = ({ show }) => {
    const useStyles = makeStyles({
        backdrop: {
            zIndex: 999,
            color: '#fff',
        },
    })

    const classes = useStyles()

    return (
        <Backdrop className={classes.backdrop} open={show}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default CustomBackdrop
