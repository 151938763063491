import {
    GET_BRANDING_REQUEST,
    GET_BRANDING_SUCCESS,
    GET_BRANDING_FAILURE,
} from '../actions/types'
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    theme: null,
    isLoading: false,
    defaultTheme: {
        palette: {
            primary: {
                main: '#C03256',
            },
            secondary: {
                main: '#42424E',
            },
            navigation: {
                main: '#F3F3F3',
            },
            header: {
                main: '#FFFFFF',
                contrastText: '#222',
            },
        },
        logoUrl: '/img/AdviserPlusLogo.png',
    },
}

export default createReducer(initialState, {
    [GET_BRANDING_REQUEST]: (state, action) => {
        state.isLoadingTheme = true
    },
    [GET_BRANDING_SUCCESS]: (state, action) => {
        state.isLoadingTheme = false
        var parsedTheme = JSON.parse(action.response.theme)
        state.theme = {
            palette: parsedTheme.palette,
            logoUrl: action.response.logoUrl,
        }
    },
    [GET_BRANDING_FAILURE]: (state, action) => {
        state.isLoadingTheme = false
        state.theme = state.defaultTheme
    },
})
