import { UserManager, WebStorageStateStore, Log } from 'oidc-client'
import httpClient from '../utils/axios'
import { handleServiceError } from '../utils/serviceResponseHelper'
import appServiceConstants from '../constants/appservice.constants'

//ensures that users are cleared down on each load
window.localStorage.removeItem(
    `oidc.user:${window.config.IDENTITY_URL}:${window.config.OIDC_CLIENT_ID}`
)

const config = {
    authority: window.config.IDENTITY_URL,
    client_id: window.config.OIDC_CLIENT_ID,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
    }/signin-oidc`,
    response_type: 'id_token token',
    scope:
        'openid profile processengineserviceapiaccess processengineuserapiaccess caseapiaccess configurationapiaccess',
    post_logout_redirect_uri: `${window.location.protocol}//${
        window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}/signout-oidc`,
    silent_redirect_uri: `${window.location.protocol}//${
        window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}/silent-oidc`,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
}

const userManager = new UserManager(config)

if (process.env.NODE_ENV !== 'production') Log.logger = console

export async function getAccessToken() {
    try {
        const user = await userManager.getUser()
        if (!user || user.expired) {
            return null
        }
        return `Bearer ${user.access_token}`
    } catch (error) {
        return Promise.reject(error)
    }
}

export async function checkServiceAccess() {
    return httpClient.get(`${window.config.SERVICE_API_URL}/v1/app`).then(
        (response) => {
            const { data: allowedAppsList } = response
            return allowedAppsList.some(
                (app) => app.id === appServiceConstants.USER_PROFILE_APP
            )
        },
        (error) => handleServiceError(error)
    )
}

export async function getUser() {
    try {
        const user = await userManager.getUser()
        if (!user || user.expired) {
            return null
        }
        return user
    } catch (error) {
        return Promise.reject(error)
    }
}

export function signInRedirect(preRedirectState) {
    return userManager.signinRedirect(preRedirectState)
}

export function signInRedirectCallback() {
    return userManager.signinRedirectCallback()
}

export function signInSilent() {
    return userManager.signinSilent()
}

export function signInSilentCallback() {
    return userManager.signinSilentCallback()
}

export function signOutRedirect() {
    return userManager.signoutRedirect()
}

export async function signOutRedirectCallback() {
    await userManager.clearStaleState()
    await userManager.removeUser()
    return userManager.signoutRedirectCallback()
}

export default userManager
