import {
    SERVICE_LABEL_DATA_REQUEST,
    SERVICE_LABEL_DATA_SUCCESS,
    SERVICE_LABEL_DATA_FAILURE,
} from './types'

import serviceLabelService from '../services/serviceLabel.service'

function serviceLabelRequest() {
    return {
        type: SERVICE_LABEL_DATA_REQUEST,
    }
}

function serviceLabelSuccess(response) {
    return {
        type: SERVICE_LABEL_DATA_SUCCESS,
        payload: response,
    }
}

function serviceLabelFailure(error) {
    return {
        type: SERVICE_LABEL_DATA_FAILURE,
        payload: error,
    }
}

export const getServiceLabelAsync = () => {
    return async (dispatch) => {
        dispatch(serviceLabelRequest())
        try {
            const response = await serviceLabelService.getServiceLabelAsync()
            return dispatch(serviceLabelSuccess(response))
        } catch (error) {
            return dispatch(serviceLabelFailure(error))
        }
    }
}
