import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from './types'

export function enqueueSnackbar(notification) {
    const key = notification.options && notification.options.key

    return {
        type: ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    }
}

export function closeSnackbar(key) {
    return {
        type: CLOSE_SNACKBAR,
        dismissAll: !key, // dismiss all if no key has been defined
        key,
    }
}

export function removeSnackbar(key) {
    return {
        type: REMOVE_SNACKBAR,
        key,
    }
}
