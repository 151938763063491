import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Collapse } from '@material-ui/core'

import EmployeeOverview from './employeeOverview'
import DrawerHeader from './drawerHeader'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    nested: {
        paddingLeft: theme.spacing(4),
        fontSize: '0.6rem',
    },
}))

const SideDrawer = ({ open, setOpen }) => {
    const classes = useStyles()

    const [userDetailsOpen, setUserDetailsOpen] = useState(false)

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <DrawerHeader>
                <IconButton onClick={() => setOpen(false)}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>

            <EmployeeOverview />

            <List component="nav">
                <Divider />
                <ListItem
                    button
                    onClick={() => setUserDetailsOpen(!userDetailsOpen)}
                >
                    <ListItemText primary={'User Details'} />
                    <ListItemIcon>
                        {userDetailsOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                </ListItem>
                <Collapse in={userDetailsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            className={classes.nested}
                            button
                            component={Link}
                            to="/employee/details"
                        >
                            <ListItemText primary={'Information'} />
                        </ListItem>
                        <ListItem
                            className={classes.nested}
                            button
                            component={Link}
                            to="/employee/absence"
                        >
                            <ListItemText primary={'Absence'} />
                        </ListItem>
                    </List>
                </Collapse>
                <Divider />
                <ListItem button component={Link} to="/cases">
                    <ListItemText primary={'Case History'} />
                </ListItem>
                <Divider />
                <ListItem button component={Link} to="/events/history">
                    <ListItemText primary={'Event History'} />
                </ListItem>
                <Divider />
                <ListItem button component={Link} to="/user/comments">
                    <ListItemText primary={'User Comments'} />
                </ListItem>
            </List>
        </Drawer>
    )
}

export default SideDrawer
