import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import TopNavbar from '../components/topNavbar'
import SideDrawer from '../components/sideDrawer'
import DrawerHeader from '../components/drawerHeader'
import { useSelector, useDispatch } from 'react-redux'
import { fetchEmployeeData } from '../actions/employeeinfo.actions'
import CustomBackdrop from '../components/customBackdrop'
import { Container } from '@material-ui/core'
import ThemeLoader from '../components/branding/themeLoader'
import { useNavigation } from '../contexts/navigation'
import { getServiceLabelAsync } from '../actions/serviceLabel.actions'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}))

const MainLayout = ({ children }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigation = useNavigation()

    const { data } = useSelector((state) => state.employeeInformation)
    const { serviceLabelData } = useSelector((state) => state.serviceLabel)

    const userId = navigation.userId

    const [open, setOpen] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        navigation.processQueryParams(window.location.href)
        window.history.replaceState(
            {},
            window.document.title,
            window.location.origin + window.location.pathname
        )
    }, [navigation])

    useEffect(() => {
        if (!data && userId) {
            setIsLoading(true)
            dispatch(fetchEmployeeData(userId))
        } else {
            setIsLoading(false)
        }
    }, [data, dispatch, userId])

    useEffect(() => {
        if (!serviceLabelData) {
            setIsLoading(true)
            dispatch(getServiceLabelAsync())
        } else {
            setIsLoading(false)
        }
    }, [dispatch, serviceLabelData])

    return (
        <ThemeLoader>
            {isLoading ? (
                <CustomBackdrop show={true} />
            ) : (
                <div className={classes.root}>
                    <CssBaseline />
                    <TopNavbar open={open} setOpen={setOpen} />
                    <SideDrawer open={open} setOpen={setOpen} />
                    <main
                        className={clsx(classes.content, {
                            [classes.contentShift]: open,
                        })}
                    >
                        <DrawerHeader />
                        <Container>{children}</Container>
                    </main>
                </div>
            )}
        </ThemeLoader>
    )
}

export default MainLayout
