import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import EmployeeInformation from './pages/employeeInformation'
import EmployeeAbsence from './pages/employeeAbsence'
import EventHistory from './pages/eventHistory'
import UserComments from './pages/userComments'
import NoPermissions from './pages/noPermissions'
import NoAccess from './pages/noAccess'
import Oops from './pages/oops'
import SignInCallback from './callbacks/signInCallback'
import SignOutCallback from './callbacks/signOutCallback'
import SignInSilentCallback from './callbacks/signInSilentCallback'
import AuthenticationProvider from './providers/authenticationProvider'
import userService from './services/auth.service'
import ProtectedRoute from './components/routing/protectedRoute'
import store from './store'
import history from './history'
import NotificationHandler from './components/notificationHandler'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import CaseHistory from './pages/caseHistory'

function App() {
    const { theme, defaultTheme } = useSelector((state) => state.branding)
    const muiTheme = createMuiTheme(theme || defaultTheme)

    return (
        <AuthenticationProvider userService={userService} store={store}>
            <SnackbarProvider>
                <NotificationHandler />
                <MuiThemeProvider theme={muiTheme}>
                    <Router history={history}>
                        <Switch>
                            <ProtectedRoute
                                exact
                                path="/"
                                component={EmployeeInformation}
                            />
                            <ProtectedRoute
                                path="/employee/details"
                                component={EmployeeInformation}
                            />
                            <ProtectedRoute
                                path="/employee/absence"
                                component={EmployeeAbsence}
                            />
                            <ProtectedRoute
                                path="/cases"
                                component={CaseHistory}
                            />
                            <ProtectedRoute
                                path="/events/history"
                                component={EventHistory}
                            />
                            <ProtectedRoute
                                path="/user/comments"
                                component={UserComments}
                            />
                            <Route
                                path="/signin-oidc"
                                component={SignInCallback}
                            />
                            <Route
                                path="/signout-oidc"
                                component={SignOutCallback}
                            />
                            <Route
                                path="/silent-oidc"
                                component={SignInSilentCallback}
                            />
                            <Route
                                path="/permissions"
                                component={NoPermissions}
                            />
                            <Route path="/access" component={NoAccess} />
                            <Route path="*" component={Oops} />
                        </Switch>
                    </Router>
                </MuiThemeProvider>
            </SnackbarProvider>
        </AuthenticationProvider>
    )
}

export default App
