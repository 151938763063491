import httpClient from '../utils/axios'

export const getEmployeeAbsencesBlob = async (userId, fileDownloadName) => {
    return httpClient
        .get(
            `${window.config.PDF_API_URL}/api/pdf/employee/${userId}/absences`,
            {
                responseType: 'blob',
            }
        )
        .then((response) => {
            downloadBlobFile(response, fileDownloadName)
        })
}

export const getEventExportBlob = async (
    userId,
    selectedTopic,
    fileDownloadName
) => {
    return httpClient
        .get(
            `${window.config.PDF_API_URL}/api/pdf/employee/${userId}/events/${selectedTopic}`,
            {
                responseType: 'blob',
            }
        )
        .then((response) => {
            downloadBlobFile(response, fileDownloadName)
        })
}

const downloadBlobFile = (response, fileDownloadName) => {
    try {
        const blob = response.data
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileDownloadName)
            return
        }

        link.setAttribute('target', '_blank')
        link.setAttribute('type', 'hidden')
        link.setAttribute('download', fileDownloadName)
        link.href = url
        document.body.appendChild(link)
        link.click()
    } catch (error) {
        console.log(error)
    }
}
